











































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import DsEditor from "@/components/ds-editor/ds-editor.vue";
import { addFangan, editFangan } from "@/request/manage";
@Component({
  components: {
    MainHeader,
    NavLeft,
    DsEditor,
  },
})
export default class Name extends Vue {
  private edit: any = false;
  private data: any = {};
  private options: any = [
    "经络数据采集报告",
    "中医体质辨识",
    "老年人体质辨识",
    "中医健康状态智能测评",
  ];
  private fangan: any = [];
  private goPreview() {
    this.$router.push({
      path: "/main/manage/fangan/preview",
      query: {
        data: JSON.stringify(this.data),
      },
    });
  }
  private editorChange(e: any) {
    this.data.content = e;
  }
  private save() {
    if (!this.data.project_name) {
      this.$message.warning("请输入方案名称");
      return;
    }
    if (!this.data.ceping_name) {
      this.$message.warning("请输入测评名称");
      return;
    }
    if (!this.data.content) {
      this.$message.warning("请输入方案内容");
      return;
    }
    if (this.data.id) {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      editFangan(this, this.data, loading).then((res: any) => {
        this.$message.success("保存成功！");
        this.$router.back();
      });
    } else {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      addFangan(this, this.data, loading).then((res: any) => {
        this.$message.success("保存成功！");
        this.$router.back();
      });
    }
  }
  private mounted() {
    if (this.$route.query.data) {
      this.data = JSON.parse((this.$route.query as any).data);
      setTimeout(() => {
        this.edit = true;
      }, 1000);
    } else {
      this.edit = true;
    }
  }
}
